import React from "react"
import {
  PageProps,
  // useStaticQuery,
  // graphql
} from "gatsby"
import classnames from "classnames"
// import BackgroundImage from "gatsby-background-image"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import Instagram from "@components/Sell/Instagram"
import MakeMoney from "@components/Sell/MakeMoney"
import EmbraceChange from "@components/Sell/EmbraceChange"
import { useTranslation } from "@hooks"

import AppScreenshot from "@components/App/AppScreenshot"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Columns from "react-bulma-components/lib/components/columns"
import Card from "react-bulma-components/lib/components/card"
import Content from "react-bulma-components/lib/components/content"
import Hero from "react-bulma-components/lib/components/hero"

import styles from "./styles.module.scss"

const Sell = (props: PageProps) => {
  const [t] = useTranslation()

  // const data = useStaticQuery(graphql`
  //   query {
  //     img: file(relativePath: { eq: "sell-on-iynk-background.jpg" }) {
  //       childImageSharp {
  //         fluid(quality: 100) {
  //           aspectRatio
  //           # ...GatsbyImageSharpFluid // With blur-up
  //           ...GatsbyImageSharpFluid_noBase64
  //         }
  //       }
  //     }
  //   }
  // `)

  const why = [
    {
      title: "Make money",
      image: <MakeMoney />,
      content:
        "Sell a few extra flash or manage all of your bookings. Whatever you want to do?",
    },
    {
      title: "Your platform",
      image: <Instagram />,
      content:
        "Instagram is noisy. IYNK has been designed just to book tattoos.",
    },
    {
      title: "Embrace Change",
      image: <EmbraceChange />,
      content:
        "Online bookings and reviews are the future, stay ahead of the curve.",
    },
  ]

  return (
    <Layout>
      <SEO
        title={t("pages.Sell.seo.title")}
        description={t("pages.Sell.seo.description")}
      />

      <Hero size="medium">
        {/* <BackgroundImage
          Tag="section"
          fluid={data.img.childImageSharp.fluid}
          className={"has-background-black"}
        > */}
        <Hero.Body className={"has-background-black"}>
          <Container className={classnames("has-text-centered")}>
            <Heading
              className={classnames(styles.Sell__heading, "has-text-white-bis")}
            >
              {t("pages.Sell.heading")}
            </Heading>
            <p
              className={classnames(
                styles.Sell__description,
                "has-text-white-bis"
              )}
            >
              Sell your work. Make money. It's simple to get started.
            </p>
          </Container>
        </Hero.Body>
        {/* </BackgroundImage> */}
      </Hero>
      <Container>
        <Section>
          <Columns breakpoint="mobile" className={"is-vcentered"}>
            {why.map((w, i) => (
              <Columns.Column
                key={i}
                mobile={{
                  size: 12,
                }}
                tablet={{
                  size: 4,
                }}
                desktop={{
                  size: 4,
                }}
                widescreen={{
                  size: 4,
                }}
                fullhd={{
                  size: 4,
                }}
                className={"has-text-centered"}
              >
                <Card className={"is-card-equal-height"}>
                  {w.image && w.image}
                  <Card.Content className={styles.Sell__whycontent}>
                    <Heading renderAs={"h4"} size={5}>
                      {w.title}
                    </Heading>
                    <p>{w.content}</p>
                  </Card.Content>
                </Card>
              </Columns.Column>
            ))}
          </Columns>
        </Section>
        <Section>
          <Columns breakpoint="mobile" className={"is-vcentered"}>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <div className={"reverse"}></div>
              <Card className={"is-card-equal-height"} shadowless>
                <Card.Content>
                  <Content>
                    <Heading renderAs={"h2"} size={4}>
                      How to sell
                    </Heading>
                    It’s easy to get started – just create an Artist account to
                    start selling. Connect your bank account. Upload some work
                    and decide on your prices. Get paid fast when you receive a
                    booking.
                  </Content>
                </Card.Content>
              </Card>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{
                size: 6,
              }}
              desktop={{
                size: 6,
              }}
              widescreen={{
                size: 6,
              }}
              fullhd={{
                size: 6,
              }}
            >
              <Card className={"is-card-equal-height"}>
                <AppScreenshot />
              </Card>
            </Columns.Column>
          </Columns>
        </Section>
      </Container>
    </Layout>
  )
}

export default Sell
